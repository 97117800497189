<template>
  <div :class="['indicator-card', mod ? `indicator-card--${mod}` : '']">
    <slot name="title">
      <div :class="{ 'indicator-card__title-wrapper': tooltipText }">
        <p class="indicator-card__title" :style="titleStyle">{{ title }}</p>

        <Tooltip v-if="tooltipText" :triggers="['click']" :modContent="modContentTooltip" class="ml-30">
          <slot name="tooltipContent">
            <span>{{ tooltipText }}</span>
          </slot>
        </Tooltip>
      </div>
    </slot>
    <slot name="values">
      <div class="indicator-card__values">
        <p class="indicator-card__value">
          {{ resolveValueFormat }}<span class="indicator-card__value-measure">{{ valueMeasure ?? '' }}</span>
        </p>
        <div class="indicator-card__percent">
          <p v-if="place" class="margin-5">{{ place }} место</p>
          <p v-else-if="percentValue" :class="percentDirection" class="margin-5">
            <i v-if="percentValue > 0" class="fas fa-solid text-green fa-arrow-up arrow"></i>
            <i v-else class="fas fa-solid text-red fa-arrow-down arrow"></i>{{ resolvePercentValue }}
          </p>
          <p class="indicator-card__year">{{ year }}<span> г.</span></p>
        </div>
      </div>
    </slot>
  </div>
</template>

<script>
  import text from '@/common/mixins/text';
  import Tooltip from '@/common/ui/Tooltip.vue';

  export default {
    name: 'IndicatorCard',
    mixins: [text],
    components: { Tooltip },
    props: {
      title: {
        type: String,
        default: '',
      },
      titleStyle: {
        type: Object,
        default: () => ({}),
      },
      value: {
        type: Number,
        default: null,
      },
      valueMeasure: {
        type: String,
        default: '',
      },
      percentValue: {
        type: Number,
        default: null,
      },
      isPercent: {
        type: Boolean,
        default: false,
      },
      year: {
        type: Number,
        default: null,
      },
      tooltipText: {
        type: String,
        default: '',
      },
      place: {
        type: Number,
        default: 0,
      },
      mod: {
        type: String,
        default: '',
        validator(val) {
          return ['inverted-card'].includes(val);
        },
      },
      modContentTooltip: {
        type: String,
        default: 'width-600',
      },
    },
    computed: {
      resolveValueFormat() {
        if (this.value == null) {
          return '-';
        }
        return this.value.toString().includes('.') ? this.formatNumber(this.value, 1, '', ',') : this.value;
      },
      percentDirection() {
        return this.percentValue > 0 ? 'text-green' : 'text-red';
      },
      resolvePercentValue() {
        const sign = this.percentValue > 0 ? '+' : '';
        return this.isPercent ? `${sign}${this.formatNumber(this.percentValue, 1)}%` : `${sign}${this.percentValue}`;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .indicator-card {
    transition: 0.2s ease-in-out;
    padding: 15px;
    background: #fff;
    box-shadow: 0 0 17px rgba(55, 55, 55, 0.08);
    border-radius: 10px;
    width: 220px;
    min-height: 160px;
    position: relative;

    :slotted(.indicator-card__title-wrapper) {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    :slotted(.indicator-card__title) {
      color: $base;
      font: $fira-14;
    }
    :slotted(.indicator-card__link) {
      position: absolute;
      inset: 0;
    }
    :slotted(.indicator-card__values) {
      position: absolute;
      bottom: 15px;
    }
    :slotted(.indicator-card__value) {
      color: $base;
      font: $fira-32-B;
    }
    :slotted(.indicator-card__value-measure) {
      margin-left: 5px;
      font: $fira-20;
    }
    :slotted(.indicator-card__percent) {
      display: flex;
      font: $fira-12;
    }
    :slotted(.indicator-card__year) {
      font: $fira-12;
    }

    :slotted(.arrow) {
      font-size: 12px;
      margin-right: 5px;
    }
    :slotted(.margin-5) {
      margin-right: 5px;
    }

    :slotted(.indicator-card__wrapper-indicators) {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-bottom: 6px;
    }

    &--inverted-card {
      max-width: none;
      width: auto;
      display: inline-block;
      min-height: auto;
      height: 100%;

      :slotted(.indicator-card__percent) {
        flex-wrap: wrap;
        flex-direction: column;
      }

      @media (min-width: $tablet) {
        .exportPdf & {
          padding-bottom: 30px;

          :slotted(.indicator-card__value) {
            margin-top: -14px;
          }
        }
      }
    }
  }
  .ml-30 {
    margin-left: 30px;
  }
</style>
