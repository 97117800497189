<template>
  <div class="map-regions-economic-relations">
    <h3 @click="onShowMap" class="title title--regions-economic cursor-pointer">
      Тепловая карта внешнеэкономических связей
      <span class="map-regions-economic-relations__icon-wrapper">
        субъектов&nbsp;РФ
        <IconComponent
          :class="['icon--full-svg', 'map-regions-economic-relations__icon-more', { show: show }]"
          name="arrowCurrentColor"
        />
      </span>
    </h3>
    <Transition>
      <div v-if="show">
        <div class="map-regions-economic-relations__params">
          <div class="map-regions-economic-relations__params-item">
            <SelectMultiComponent
              v-model="regionSelect"
              :options="listRegions"
              placeholder="Выберите субъект РФ"
              modifier="rectangular"
              valueProp="id"
              track-by="name"
              label="name"
            />
          </div>
        </div>
        <div v-click-outside="closePopupInfo" class="map-regions-economic-relations__map-wrap">
          <div class="map-regions-economic-relations__map" id="map-regions-economic-relations"></div>
          <Transition>
            <MapPopup v-if="openPopupInfo" mod="regions" @closePopup="closePopupInfo">
              <template v-slot:header>
                <p class="map-regions-economic-relations__popup-name">{{ infoPopup.name }}</p>
              </template>
              <template v-slot:body>
                <p class="map-regions-economic-relations__popup-year">{{ year }} год</p>
                <p class="map-regions-economic-relations__popup-rating">
                  {{ infoPopup.position }} место в рейтинге эффективности
                </p>
                <ButtonComponent
                  @click="toPassportPage(infoPopup.id)"
                  class="button-redesign--commission-form"
                  mod="gradient-bg"
                  >Подробнее</ButtonComponent
                >
              </template>
            </MapPopup>
          </Transition>
        </div>
        <MapScaleQuantity name="Индекс эффективности субъектов" :squareBar="squareBar" />
      </div>
    </Transition>
  </div>
</template>

<script>
  import L from 'leaflet';

  import ButtonComponent from '@/common/components/redesigned/ButtonComponent.vue';
  import MapScaleQuantity from '@/common/components/redesigned/MapScaleQuantity.vue';
  import MapPopup from '@/common/components/redesigned/popups/MapPopup.vue';
  import SelectMultiComponent from '@/common/components/SelectMultiComponent.vue';
  import regions from '@/common/region.json';
  import IconComponent from '@/common/ui/IconComponent.vue';

  import { MAP_REGION_SCALE } from '../../utils/constants.js';

  export default {
    name: 'MapRegionsEconomicRelations',
    components: { MapScaleQuantity, MapPopup, ButtonComponent, IconComponent, SelectMultiComponent },
    props: {
      listRegions: {
        type: Array,
        default: () => [],
      },
      listRegionEffective: {
        type: Array,
        default: () => [],
      },
      year: {
        type: [String, Number],
        default: '',
      },
    },
    data() {
      return {
        show: false,
        regions,
        center: [66, 100],
        map: null,
        geoJsonLayer: null,
        openPopupInfo: false,
        regionSelect: '',
        infoPopup: {
          id: '',
          name: '',
          rating: 0,
          position: null,
        },
        squareBar: MAP_REGION_SCALE,
      };
    },
    methods: {
      onShowMap() {
        this.show = !this.show;
        if (this.show)
          setTimeout(() => {
            this.onDrawingMap();
          }, 0);
      },

      onDrawingMap() {
        let mapContainer = window.document.getElementById('map-regions-economic-relations');
        while (mapContainer.firstChild) {
          mapContainer.removeChild(mapContainer.firstChild);
        }
        if (this.map) {
          this.map.remove();
          this.map = null;
        }

        this.map = L.map('map-regions-economic-relations').setView(this.center, 1);

        L.tileLayer('', {
          maxZoom: 5,
          minZoom: 3,
        }).addTo(this.map);

        this.geoJsonLayer = L.geoJSON(this.regions, {
          weight: 0.5,
          fillOpacity: 1,
        }).addTo(this.map);

        this.restyleGeoJsonLayer();
      },

      restyleGeoJsonLayer() {
        if (this.geoJsonLayer) {
          this.geoJsonLayer.eachLayer(this.restyleLayer);
        }
      },

      styleMap(feature) {
        let color = this.squareBar[0].color;
        let value = '';

        const code = feature.properties.code;

        const index = this.listRegionEffective.findIndex((i) => +i.code === +code);
        if (index != -1) {
          value = +this.listRegionEffective[index].value;
          if (this.squareBar[1].from <= value && this.squareBar[1].to >= value) {
            color = this.squareBar[1].color;
          } else if (this.squareBar[2].from <= value && this.squareBar[2].to >= value) {
            color = this.squareBar[2].color;
          } else if (this.squareBar[3].from <= value && this.squareBar[3].to >= value) {
            color = this.squareBar[3].color;
          } else if (this.squareBar[4].from <= value && this.squareBar[4].to >= value) {
            color = this.squareBar[4].color;
          } else if (this.squareBar[5].from <= value) {
            color = this.squareBar[5].color;
          }
        }
        return { color: '#319CFF', fillColor: color, weight: 1, opacity: 1 };
      },

      onEachFeature(feature, layer) {
        const self = this;
        layer.on('mouseup', () => {
          const index = this.listRegionEffective.findIndex((i) => +i.code === +feature.properties.code);
          if (index != -1) {
            this.infoPopup.id = this.listRegionEffective[index].id;
            this.infoPopup.name = this.listRegionEffective[index].name;
            this.infoPopup.rating = this.listRegionEffective[index].value;
            this.infoPopup.position = index + 1;
            this.openPopupInfo = true;
          }
        });
        layer.on('mouseover', function () {
          this.unbindTooltip();
          const index = self.listRegionEffective.findIndex((i) => +i.code === +feature.properties.code);
          if (index != -1) this.bindTooltip(self.listRegionEffective[index].name).openTooltip();
        });
        layer.on('mouseout', function () {
          this.unbindTooltip();
        });
      },

      restyleLayer(layer) {
        let style = this.styleMap(layer.feature);
        layer.setStyle(style);
        this.onEachFeature(layer.feature, layer);
      },

      closePopupInfo() {
        this.openPopupInfo = false;
      },

      toPassportPage(id) {
        this.$router.push({ name: 'PassportForeignEconomicRelations', query: { regionId: id } });
      },
    },

    watch: {
      regionSelect() {
        this.toPassportPage(this.regionSelect);
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/theme/default/title.scss';

  .map-regions-economic-relations {
    margin-bottom: 40px;

    &__icon-wrapper {
      display: inline-flex;
      align-items: center;
      gap: 4px;
      font: inherit;
    }

    &__icon-more {
      display: inline-flex;
      width: 16px;
      margin-left: 3px;

      &.show {
        transform: scale(1, -1) translateY(3px);
      }

      @media (min-width: $tablet) {
        .exportPdf & {
          transform: translateY(15px);
          &.show {
            transform: scale(1, -1) translateY(-10px);
          }
        }
      }
    }

    &__map {
      height: 621px;
      background: transparent;
      border-color: $blue;
      z-index: 0;
      margin-bottom: 24px;

      &-wrap {
        position: relative;
      }
    }

    &__params {
      display: grid;
      grid-template-columns: repeat(2, 308px);
      gap: 16px;
      margin-bottom: 24px;
    }

    &__popup {
      &-name {
        font: $fira-14-20-B;
      }
      &-year {
        font: $fira-12;
        margin-bottom: 8px;
      }
      &-rating {
        font: $fira-12-B;
        margin-bottom: 16px;
      }
    }

    @media (max-width: $laptop) {
      &__params {
        grid-template-columns: repeat(1, 100%);
        gap: 8px;
      }

      &__map {
        height: 564px;
        max-height: 90vh;
      }
    }
  }

  .v-enter-active,
  .v-leave-active {
    transition: opacity 0.5s ease;
  }

  .v-enter-from,
  .v-leave-to {
    opacity: 0;
  }
</style>

<style lang="scss">
  .map-regions-economic-relations {
    .leaflet-tooltip {
      box-shadow: none;
      font: $fira-14;

      &::before {
        content: none;
      }
    }

    .leaflet-interactive {
      outline: none;
    }
  }
</style>
