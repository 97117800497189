<template>
  <div class="tooltip-content-efficiency-index">
    <p>Кол-во мероприятий в сфере ВЭС:</p>
    <ul>
      <li>1-10 мероприятий - 5 баллов</li>
      <li>11-50 мероприятий - 20 баллов</li>
      <li>&gt; 50 мероприятий - 40 баллов</li>
    </ul>
    <p>Заключенные соглашения без стоимостной оценки:</p>
    <ul>
      <li>1-3 соглашений - 3 балла</li>
      <li>4-10 соглашений - 10 баллов</li>
      <li>&gt; 10 соглашений - 30 баллов</li>
    </ul>
    <p>Заключенные соглашения со стоимостной оценкой:</p>
    <ul>
      <li>1-3 соглашений - 10 баллов</li>
      <li>4-15 соглашений - 20 баллов</li>
      <li>&gt; 15 соглашений - 30 баллов</li>
    </ul>
    <p>Экспорт (по сравнению с прошлым годом):</p>
    <ul>
      <li>0%-5% - 4 балла</li>
      <li>5%-10% - 6 баллов</li>
      <li>10%-20% - 8 баллов</li>
      <li>&gt; 20% - 13 баллов</li>
      <li>Отрицательная динамика (без учета абсолютного показателя) - 0 баллов</li>
    </ul>
    <p>Несырьевой неэнергетический экспорт (по сравнению с прошлым годом):</p>
    <ul>
      <li>0%-5% - 6 баллов</li>
      <li>5%-10% - 8 баллов</li>
      <li>10%-20% - 10 баллов</li>
      <li>&gt; 20% - 14 баллов</li>
      <li>Отрицательная динамика (без учета абсолютного показателя) - 0 баллов</li>
    </ul>
    <p>Количество стран-партнеров по экспорту (по сравнению с прошлым годом):</p>
    <ul>
      <li>Положительная динамика/без изменений (без учета абсолютного показателя) - 5 баллов</li>
      <li>Отрицательная динамика (без учета абсолютного показателя) - 0 баллов</li>
    </ul>
    <p>Количество стран-партнеров по импорту (по сравнению с прошлым годом)</p>
    <ul>
      <li>Положительная динамика/без изменений (без учета абсолютного показателя) - 3 балла</li>
      <li>Отрицательная динамика (без учета абсолютного показателя) - 0 баллов</li>
    </ul>
    <p>
      Рейтинг эффективности среди субъектов Российской Федерации складывается из количества мероприятий
      внешнеэкономического характера, которые влияют на экономическое развитие региона, а также заключенных соглашений
      без стоимостной оценки и со стоимостной оценкой.
    </p>
    <span>*если несколько субъектов занимают одинаковое место, то рейтинг формируется в алфавитном порядке.</span>
  </div>
</template>

<script>
  export default {
    name: 'TooltipContentEfficiencyIndex',
  };
</script>

<style lang="scss" scoped>
  .tooltip-content-efficiency-index {
    p {
      font: $fira-16-M;
      margin-bottom: 10px;
    }

    ul {
      &:not(:last-of-type) {
        margin-bottom: 20px;
      }

      li {
        font: $fira-14;
        margin-left: 16px;
        padding-left: 2px;

        &:not(:last-of-type) {
          margin-bottom: 5px;
        }
      }
    }
  }
</style>
