<template>
  <div class="card-wrapper-ves">
    <slot></slot>
  </div>
</template>

<script>
  export default {
    name: 'CardsWrapper',
  };
</script>

<style lang="scss" scoped>
  .card-wrapper-ves {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 16px;
    margin-bottom: 24px;

    @media (max-width: $tablet) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: $laptop) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: $mobile) {
      grid-template-columns: 1fr;
    }
  }
</style>
