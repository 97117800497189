<template>
  <div class="selection-block-ves">
    <h3 class="title title--regions-economic selection-block-ves__title mb-0">
      {{ title }}
    </h3>
    <div class="selection-block-ves__select-wrap">
      <div class="selection-block-ves__select-source">Источник: {{ source }}</div>
      <div class="selection-block-ves__select-field">
        <SelectMultiComponent
          v-model="getYear"
          :options="yearOptions"
          :loadingList="loadingOptions"
          placeholder="Выберите"
          modifier="rectangular"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import SelectMultiComponent from '@/common/components/SelectMultiComponent.vue';

  export default {
    name: 'SelectionBlock',
    components: { SelectMultiComponent },
    emits: ['update:year'],
    props: {
      title: {
        type: String,
        default: '',
      },
      source: {
        type: String,
        default: '',
      },
      year: {
        type: [String, Number],
        default: '',
      },
      yearOptions: {
        type: Array,
        default: () => [],
      },
      loadingOptions: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      getYear: {
        get() {
          return this.year || new Date().getFullYear();
        },
        set(value) {
          this.$emit('update:year', value);
        },
      },
    },
  };
</script>

<style lang="scss" scoped>
  .selection-block-ves {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 24px;

    &__select {
      &-wrap {
        display: flex;
        align-items: center;
        gap: 12px;
      }

      &-source {
        font: $fira-16;
      }

      &-field {
        width: 164px;
      }
    }
  }
</style>
