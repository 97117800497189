import { OPTIONS_EVENTS_ID } from '../utils/constants.js';

export default {
  props: {
    customDataLabelFormatter: {
      type: Function,
      default: (value) => {
        return new Intl.NumberFormat('ru-RU', { minimumFractionDigits: 1 }).format(+value.toFixed(1));
      }
    },

    customTooltipBodyFormatter: {
      type: Function,
      default: (value) => {
        value = new Intl.NumberFormat('ru-RU', { minimumFractionDigits: 1 })
          .format(+value.toFixed(1));
        return value + ' млн $';
      }
    }
  },

  data() {
    return {
      sortSelectedID: OPTIONS_EVENTS_ID,
      yearMonthSelectId: `${new Date().toISOString().slice(0, 7).replace(/-/, '.')}.01`,
      options: {
        indexAxis: 'y',
        locale: 'ru-RU',
        clip: false,
        layout: {
          padding: {
            right: 70,
          },
        },
        plugins: {
          tooltip: {
            enabled: false,
            external: (context) => {
              // Tooltip Element
              let tooltipEl = document.getElementById('chartjs-tooltip');
              const tooltipContainer = document.querySelector('.tooltip-graph');

              // создать при первом рендере
              if (!tooltipEl) {
                tooltipEl = document.createElement('div');
                tooltipEl.id = 'chartjs-tooltip';
                tooltipEl.innerHTML = '<div></div>';
                tooltipContainer.appendChild(tooltipEl);
              }

              // скрыть, если нет тултипа
              const tooltipModel = context.tooltip;
              if (tooltipModel.opacity === 0) {
                tooltipEl.style.opacity = 0;
                return;
              }

              // установить позицию
              tooltipEl.classList.remove('above', 'below', 'no-transform');
              if (tooltipModel.yAlign) {
                tooltipEl.classList.add(tooltipModel.yAlign);
              } else {
                tooltipEl.classList.add('no-transform');
              }

              // установить наполнение (текст)
              if (tooltipModel.body) {
                const { dataset, dataIndex } = tooltipModel.dataPoints[0];
                const body = this.customTooltipBodyFormatter(dataset.data[dataIndex])

                let innerHtml = `
                  <div class="custom-chart-tooltip custom-chart-tooltip--economic">
                    <p class="mb-8">${dataset.label}</p>
                    <div class="custom-chart-tooltip__wrap-info">
                      <span class="circle" style="background: ${dataset.backgroundColor}"></span>
                      <p>${body}</p>
                    </div>
                  </div>
                `;

                let tableRoot = tooltipEl.querySelector('div');
                tableRoot.innerHTML = innerHtml;
              }

              const position = context.chart.canvas.getBoundingClientRect();

              // установка стилей, позиции
              tooltipEl.style.opacity = 1;
              tooltipEl.style.position = 'absolute';
              tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX / 2 + 'px';
              tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY - 60 + 'px';
              tooltipEl.style.cursor = 'pointer';
              tooltipEl.style.padding = tooltipModel.padding + 'px ' + tooltipModel.padding + 'px';
              tooltipEl.style.pointerEvents = 'none';
            },
          },
          legend: {
            display: false,
          },
          datalabels: {
            clamp: true,
            align: 'end',
            offset: 0,
            anchor: 'end',
            color: '#231F20',
            textAlign: 'end',
            font: {
              size: 16,
              family: 'Fira Sans',
              lineHeight: 1,
              weight: '400',
            },
            formatter: this.customDataLabelFormatter,
          },
        },
        scales: {
          x: {
            display: false,
          },
          y: {
            grid: {
              display: false,
            },
            ticks: {
              font: {
                size: 16,
                family: 'Fira Sans',
                lineHeight: 1,
                weight: '400',
              },
              color: '#000',
              crossAlign: 'far',
            },
          },
        },
      },
    };
  },
};
