<template>
  <div class="heat-map-foreign-economic-relations">
    <PageTitle title="Общая информация о внешнеэкономических связях субъектов РФ">
      <template v-slot:button>
        <ButtonComponent @click="downloadPDF" class="button-redesign--download-icon-fill button-redesign--mobile-full">
          <IconComponent name="download-array" />
          Скачать PDF
        </ButtonComponent>
      </template>
    </PageTitle>
    <div class="container-page big heat-map-foreign-economic-relations__container export-page tooltip-graph">
      <SelectionBlock
        source="ЕХД"
        :title="getTitleBaseInfo"
        :yearOptions="yearOptions"
        :loadingOptions="getYearOptionsLoad"
        v-model:year="yearSubjects"
      />
      <IndicatorsCards :topRegionsAgreementsInfo="topRegionsAgreementsInfo" />
      <BlockTopRating
        title="Топ-10 субъектов&nbsp;РФ с&nbsp;наивысшим индексом эффективности"
        :lists="topEffectiveRegionsInfo"
        modContentTooltip="width-600"
        moreButton
        @more="moreTopEffectiveRegions"
      >
        <template v-slot:tooltipContent>
          <TooltipContentEfficiencyIndex />
        </template>
      </BlockTopRating>
      <MapRegionsEconomicRelations
        :listRegions="getRegionsList"
        :listRegionEffective="topEffectiveRegionsInfo"
        :year="yearSubjects"
      />
      <SelectionBlock
        title="Рейтинги субъектов РФ"
        source="ЕХД"
        :yearOptions="yearOptions"
        :loadingOptions="getYearOptionsLoad"
        v-model:year="yearRating"
      />
      <BlockTopRating
        title="Топ-10 субъектов РФ по"
        class="block-top-rating--select-title"
        :seriesData="getSeriesTopSortYear"
        :optionSelect="currentPeriodOptionsSelectList"
        @changeSelectSort="changeSelectSort"
        :customDataLabelFormatter="(x) => x"
        :customTooltipBodyFormatter="(x) => x"
      />
      <BlockTopRating
        title="Топ-10 субъектов РФ по"
        class="block-top-rating--select-title"
        source="ЕХД"
        :seriesData="getSeriesTopSortMonth"
        :optionSelect="pastPeriodOptionsSelectList"
        :optionSelectSource="yearMonthOptions"
        :loadSelectYearMonth="getYearAndMonthOptionsLoad"
        @changeSelectSort="changeSelectSortYearMonth"
        @changeSelectYearMonth="changeSelectYearMonth"
        :customDataLabelFormatter="(x) => x"
        :customTooltipBodyFormatter="(x) => x"
      />
      <BlockTopRating
        title="Топ-10 субъектов РФ по товарообороту, млн $"
        :seriesData="topRegionsByTurnoverInfo.graph"
      />
      <BlockTopRating title="Топ-10 субъектов РФ по экспорту, млн $" :seriesData="topRegionsByExportInfo.graph" />
      <BlockTopRating title="Топ-10 субъектов РФ по импорту, млн $" :seriesData="topRegionsByImportInfo.graph" />
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import html2pdf from 'html2pdf.js';

  import ButtonComponent from '@/common/components/redesigned/ButtonComponent.vue';
  import PageTitle from '@/common/components/redesigned/PageTitle.vue';
  import SelectionBlock from '@/common/components/redesigned/SelectionBlock';
  import wordDeclension from '@/common/mixins/wordDeclension';
  import IconComponent from '@/common/ui/IconComponent.vue';

  import BlockTopRating from '../components/BlockTopRating';
  import IndicatorsCards from '../components/informationEconomic/IndicatorsCards';
  import MapRegionsEconomicRelations from '../components/informationEconomic/MapRegionsEconomicRelations';
  import TooltipContentEfficiencyIndex from '../components/informationEconomic/TooltipContentEfficiencyIndex';
  import foreignEconomicPhrases from '../mixins/foreignEconomicPhrases.js';
  import { BASE_VES_ACTIONS_NAME, BASE_VES_GETTERS_NAME } from '../store/base/name';
  import { ECONOMIC_RELATIONS_ACTIONS_NAME, ECONOMIC_RELATIONS_GETTERS_NAME } from '../store/economicRelations/name';
  import { OPTIONS_EVENTS_ID, PERIOD_CAP, SKELETON_GRAPHS } from '../utils/constants.js';

  import { DIRECTIVES_ACTIONS_NAME, DIRECTIVES_GETTERS_NAME } from '@/store/directives';

  export default {
    name: 'HeatMapForeignEconomicRelations',
    components: {
      PageTitle,
      MapRegionsEconomicRelations,
      BlockTopRating,
      ButtonComponent,
      SelectionBlock,
      TooltipContentEfficiencyIndex,
      IconComponent,
      IndicatorsCards,
    },
    mixins: [wordDeclension, foreignEconomicPhrases],
    data() {
      // часть находится в mixin'е foreignEconomicPhrases
      return {
        currentYear: new Date().getFullYear(),
        yearSubjects: new Date().getFullYear(),
        yearRating: new Date().getFullYear(),

        quantityTopEffective: 10,
        maxLengthGraph: 10,

        selectSortYearMonth: OPTIONS_EVENTS_ID,

        topRegionsByTurnoverInfo: SKELETON_GRAPHS,
        topRegionsByExportInfo: SKELETON_GRAPHS,
        topRegionsByImportInfo: SKELETON_GRAPHS,
        topRegionsByEventsInfo: SKELETON_GRAPHS,
        topRegionsByAgreementsInfo: SKELETON_GRAPHS,
        topMonthRegionsByEventsInfo: SKELETON_GRAPHS,
        topMonthRegionsByAgreementsInfo: SKELETON_GRAPHS,
      };
    },
    computed: {
      ...mapGetters({
        topRegionsAgreementsInfo: ECONOMIC_RELATIONS_GETTERS_NAME.topRegionsAgreementsInfo,
        topEffectiveRegionsInfo: ECONOMIC_RELATIONS_GETTERS_NAME.topEffectiveRegionsInfo,

        yearOptions: BASE_VES_GETTERS_NAME.getYearOptions,
        yearMonthOptions: BASE_VES_GETTERS_NAME.getYearAndMonthOptions,
        getYearOptionsLoad: BASE_VES_GETTERS_NAME.getYearOptionsLoad,
        getYearAndMonthOptionsLoad: BASE_VES_GETTERS_NAME.getYearAndMonthOptionsLoad,

        getRegionsList: DIRECTIVES_GETTERS_NAME.getRegionsListWithId,
      }),

      getTitleBaseInfo() {
        return `${this.yearSubjects} год ${this.yearSubjects === this.currentYear ? '(Текущий)' : ''}`;
      },

      getSeriesTopSortYear() {
        if (+this.sortSelectedID === OPTIONS_EVENTS_ID) {
          return this.topRegionsByEventsInfo.graph;
        }
        return this.topRegionsByAgreementsInfo.graph;
      },

      getMoreTopSortYear() {
        if (+this.sortSelectedID === OPTIONS_EVENTS_ID) {
          return this.topRegionsByEventsInfo.list;
        }
        return this.topRegionsByAgreementsInfo.list;
      },

      getSeriesTopSortMonth() {
        if (+this.selectSortYearMonth === OPTIONS_EVENTS_ID) {
          return this.topMonthRegionsByEventsInfo.graph;
        }
        return this.topMonthRegionsByAgreementsInfo.graph;
      },

      getMoreTopSortMonth() {
        if (+this.selectSortYearMonth === OPTIONS_EVENTS_ID) {
          return this.topMonthRegionsByEventsInfo.list;
        }
        return this.topMonthRegionsByAgreementsInfo.list;
      },

      getLastMonthOptions() {
        const index = this.yearMonthOptions.findIndex((i) => i.id === this.yearMonthSelectId);
        if (index != -1) return this.yearMonthOptions[index]?.name;
        return PERIOD_CAP;
      },

      getSecondLastMonthOptions() {
        const index = this.yearMonthOptions.findIndex((i) => i.id === this.yearMonthSelectId);
        if (index != -1) {
          const year = this.yearMonthOptions[index]?.name.replace(/[^\d]/g, '');
          const month = this.yearMonthOptions[index]?.name.replace(/[0-9]/g, '');
          return `${+year - 1}${month}`;
        }
        return PERIOD_CAP;
      },

      currentPeriodOptionsSelectList() {
        return [
          {
            id: 1,
            name: 'количеству мероприятий за год нарастающим итогом',
          },
          {
            id: 2,
            name: 'количеству соглашений',
          },
        ];
      },

      pastPeriodOptionsSelectList() {
        return [
          {
            id: 1,
            name: 'количеству мероприятий за истекший период года нарастающим итогом',
          },
          {
            id: 2,
            name: 'количеству соглашений',
          },
        ];
      },
    },
    created() {
      this.getYearLast();
      this.getRegions();
      this.loadPage();
    },
    methods: {
      ...mapActions({
        getRegions: DIRECTIVES_ACTIONS_NAME.getRegions,

        getYearLast: BASE_VES_ACTIONS_NAME.getYearLast,

        getTopRegionsAgreements: ECONOMIC_RELATIONS_ACTIONS_NAME.getTopRegionsAgreements,
        getTopEffectiveRegions: ECONOMIC_RELATIONS_ACTIONS_NAME.getTopEffectiveRegions,
        getTopRegionsByTurnover: ECONOMIC_RELATIONS_ACTIONS_NAME.getTopRegionsByTurnover,
        getTopRegionsByExport: ECONOMIC_RELATIONS_ACTIONS_NAME.getTopRegionsByExport,
        getTopRegionsByImport: ECONOMIC_RELATIONS_ACTIONS_NAME.getTopRegionsByImport,
        getTopRegionsByEvents: ECONOMIC_RELATIONS_ACTIONS_NAME.getTopRegionsByEvents,
        getTopRegionsByAgreements: ECONOMIC_RELATIONS_ACTIONS_NAME.getTopRegionsByAgreements,
        getTopMonthRegionsByEvents: ECONOMIC_RELATIONS_ACTIONS_NAME.getTopMonthRegionsByEvents,
        getTopMonthRegionsByAgreements: ECONOMIC_RELATIONS_ACTIONS_NAME.getTopMonthRegionsByAgreements,
      }),

      getFormatYear(prams) {
        return `${this[prams]}.01.01`;
      },

      loadPage() {
        this.loadSectionFirst();
        this.loadSectionSecond();
        this.loadSectionThird();
      },

      loadSectionFirst() {
        this.getTopRegionsAgreements({ requestedYear: this.getFormatYear('yearSubjects') });
        this.getTopEffectiveRegions({ requestedYear: this.getFormatYear('yearSubjects') });
      },

      async loadSectionSecond() {
        const resTurnover = await this.getTopRegionsByTurnover({ requestedYear: this.getFormatYear('yearRating') });
        if (resTurnover.success) this.topRegionsByTurnoverInfo = this.dataDistributionGraph(resTurnover.data);
        const resExport = await this.getTopRegionsByExport({ requestedYear: this.getFormatYear('yearRating') });
        if (resExport.success) this.topRegionsByExportInfo = this.dataDistributionGraph(resExport.data);
        const resImport = await this.getTopRegionsByImport({ requestedYear: this.getFormatYear('yearRating') });
        if (resImport.success) this.topRegionsByImportInfo = this.dataDistributionGraph(resImport.data);
        const resEvents = await this.getTopRegionsByEvents({ requestedYear: this.getFormatYear('yearRating') });
        if (resEvents.success) this.topRegionsByEventsInfo = this.dataDistributionGraph(resEvents.data);
        const resAgreements = await this.getTopRegionsByAgreements({ requestedYear: this.getFormatYear('yearRating') });
        if (resAgreements.success) this.topRegionsByAgreementsInfo = this.dataDistributionGraph(resAgreements.data);
      },

      async loadSectionThird() {
        const resEventsMonth = await this.getTopMonthRegionsByEvents({
          requestedYear: this.yearMonthSelectId,
        });
        if (resEventsMonth.success)
          this.topMonthRegionsByEventsInfo = this.dataDistributionGraph(resEventsMonth.data, 'month');
        const resAgreementsMonth = await this.getTopMonthRegionsByAgreements({
          requestedYear: this.yearMonthSelectId,
        });
        if (resAgreementsMonth.success)
          this.topMonthRegionsByAgreementsInfo = this.dataDistributionGraph(resAgreementsMonth.data, 'month');
      },

      downloadPDF() {
        const exportContainer = document.querySelector('.export-page');
        if (!exportContainer) {
          return;
        }
        const clonedElement = exportContainer.cloneNode(true);
        clonedElement.classList.add('exportPdf');

        // копирование изображений canvas
        const originalCanvases = exportContainer.querySelectorAll('canvas');
        const clonedCanvases = clonedElement.querySelectorAll('canvas');
        originalCanvases.forEach((element, index) => {
          clonedCanvases[index].getContext('2d').drawImage(element, 0, 0);
        });

        const opt = {
          margin: 0,
          filename: 'Внешнеэкономические_связи_субъектов_РФ.pdf',
          image: { type: 'png', quality: 1 },
          html2canvas: {
            windowWidth: 1440,
            dpi: 190,
            scale: 1,
            useCORS: false,
          },
          jsPDF: {
            unit: 'mm',
            format: 'a3',
            orientation: 'portrait',
            align: 'left',
            baseline: 'middle',
            putOnlyUsedFonts: true,
          },
          pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
        };

        html2pdf()
          .set(opt)
          .from(clonedElement)
          .save()
          .then(() => {
            clonedElement.remove();
          });
      },

      moreTopEffectiveRegions(state) {
        if (state) {
          this.quantityTopEffective = this.topEffectiveRegionsInfo.length;
        } else {
          this.quantityTopEffective = 10;
        }
      },

      dataDistributionGraph(data, typeLabel) {
        const currentYear = [];
        const lastYear = [];
        const labels = [];
        const list = [];

        data.forEach((i, index) => {
          labels.push(`${index + 1} ${i.regionName}`);
          currentYear.push(i.requestedYearValue || 0);
          lastYear.push(i.lastYearValue || 0);
          list.push({
            ...i,
            name: i.regionName,
          });
        });

        return {
          graph: {
            datasets: [
              {
                data: currentYear,
                label: typeLabel != 'month' ? this.yearRating : this.getLastMonthOptions,
                barPercentage: '1',
                backgroundColor: '#01A39D',
              },
              {
                data: lastYear,
                label: typeLabel != 'month' ? +this.yearRating - 1 : this.getSecondLastMonthOptions,
                barPercentage: '1',
                backgroundColor: '#D1D5DB',
              },
            ],
            labels: labels,
          },
          list: list,
        };
      },

      changeSelectSort(val) {
        this.sortSelectedID = val;
      },

      changeSelectSortYearMonth(val) {
        this.selectSortYearMonth = val;
      },

      changeSelectYearMonth(val) {
        this.yearMonthSelectId = val;
      },
    },
    watch: {
      yearSubjects() {
        this.loadSectionFirst();
      },
      yearRating() {
        this.loadSectionSecond();
      },
      yearMonthSelectId() {
        this.loadSectionThird();
      },
    },
  };
</script>

<style lang="scss">
  .heat-map-foreign-economic-relations__container {
    &.exportPdf {
      .map-regions-economic-relations {
        display: none;
      }
    }
  }
</style>
