<template>
  <div class="block-top-rating">
    <div class="block-top-rating__title-wrap">
      <h4 v-if="title" v-html="title" class="title title--list-top" ref="title"></h4>
      <Tooltip
        v-if="$slots.tooltipContent"
        :triggers="['click']"
        :modContent="modContentTooltip"
        position="auto-start"
        class="tooltip-component--mobile-big tooltip-component--investment"
      >
        <slot name="tooltipContent"></slot>
      </Tooltip>
      <div v-if="optionSelect.length" class="block-top-rating__select-wrap">
        <SelectMultiComponent
          v-model="sortSelectedID"
          :options="optionSelect"
          :placeholder="placeholderSelect"
          modifier="blue"
          valueProp="id"
          track-by="name"
          label="name"
        />
      </div>
      <div v-if="optionSelectSource.length" class="block-top-rating__source-wrap">
        <div class="block-top-rating__source-source">Источник: {{ source }}</div>
        <div class="block-top-rating__source-field">
          <SelectMultiComponent
            v-model="yearMonthSelectId"
            :options="optionSelectSource"
            :loadingList="loadSelectYearMonth"
            placeholder="Выберите"
            modifier="rectangular"
            valueProp="id"
            track-by="name"
            label="name"
          />
        </div>
      </div>
    </div>

    <ol v-if="computedList.length">
      <li v-for="list in computedList" :key="list.id">
        {{ list.name }}
        <span class="block-top-rating__count" v-if="list.value">({{ list.value }})</span>
      </li>
    </ol>

    <TopCountryStatisticChart
      v-if="seriesData"
      :title="titleChart"
      :seriesProp="computedSeriesData"
      :categories="[]"
      :options="options"
      :isShowLegend="true"
      :height="600"
      class="bar-chart-tooltip--rating"
    />

    <template v-if="needShowMoreButton">
      <ButtonComponent mod="more" @click="onShowMore">
        {{ showMore ? 'Скрыть' : 'Подробнее' }}
        <IconComponent v-if="showMore" class="icon--full-svg icon--hide-rating" name="arrowCurrentColor" />
        <IconComponent v-else class="icon--full-svg" name="plus-mini" />
      </ButtonComponent>
    </template>
  </div>
</template>

<script>
  import ButtonComponent from '@/common/components/redesigned/ButtonComponent.vue';
  import SelectMultiComponent from '@/common/components/SelectMultiComponent.vue';
  import IconComponent from '@/common/ui/IconComponent.vue';
  import Tooltip from '@/common/ui/Tooltip.vue';

  import TopCountryStatisticChart from '@/components/ChartComponents/TopCountryStatisticChart';

  import foreignEconomicPhrases from '../mixins/foreignEconomicPhrases.js';

  export default {
    name: 'BlockTopRating',
    components: { TopCountryStatisticChart, Tooltip, ButtonComponent, IconComponent, SelectMultiComponent },
    emits: ['more', 'changeSelectSort', 'changeSelectYearMonth'],
    mixins: [foreignEconomicPhrases],
    props: {
      title: {
        type: String,
        default: '',
      },
      source: {
        type: String,
        default: '',
      },
      lists: {
        type: Array,
        default: () => [],
      },
      titleChart: {
        type: String,
        default: '',
      },
      seriesData: {
        type: Object,
      },
      modContentTooltip: {
        type: String,
        default: '',
      },
      optionSelect: {
        type: Array,
        default: () => [],
      },
      placeholderSelect: {
        type: String,
        default: 'Выберите',
      },
      optionSelectSource: {
        type: Array,
        default: () => [],
      },
      loadSelectYearMonth: {
        type: Boolean,
        default: false,
      },
      initialDisplayCount: {
        type: Number,
        default: 10
      },
      /*
      в миксине `foreignEconomicPhrases` также определены `props`:
       - customDataLabelFormatter
       - customTooltipBodyFormatter
      */
    },
    // часть находится в mixin'е foreignEconomicPhrases
    data() {
      return {
        showMore: false,
      };
    },

    computed: {
      computedList() {
        return this.showMore
          ? this.lists
          : this.lists.slice(0, this.initialDisplayCount);
      },

      computedSeriesData() {
        return this.showMore
          ? this.seriesData
          : {
            datasets: (this.seriesData?.datasets ?? []).map((dataset) => {
              const { data, ...rest } = dataset;
              return {
                ...rest,
                data: data.slice(0, this.initialDisplayCount),
              }
            }),
            labels: (this.seriesData?.labels ?? []).slice(0, this.initialDisplayCount),
          }
      },

      needShowMoreButton() {
        return this.lists?.length > this.initialDisplayCount
          || this.seriesData?.labels?.length > this.initialDisplayCount
      }
    },

    methods: {
      onShowMore() {
        this.showMore = !this.showMore;
        this.$emit('more', this.showMore);
        if (!this.showMore) this.scrollTop();
      },

      scrollTop() {
        const title = this.$refs.title;
        let top = window.scrollY + title.getBoundingClientRect().y - 40;
        window.scrollTo({
          top,
          behavior: 'smooth',
        });
      },
    },
    watch: {
      sortSelectedID(val) {
        this.$emit('changeSelectSort', val);
      },
      yearMonthSelectId(val) {
        this.$emit('changeSelectYearMonth', val);
      },
    },
  };
</script>

<style lang="scss">
  @import '@/components/ChartComponents/styles/custom-tooltip.scss';
</style>

<style lang="scss" scoped>
  @import '@/assets/theme/default/title.scss';

  .block-top-rating {
    margin-bottom: 24px;

    &__title-wrap {
      margin-bottom: 16px;
      gap: 5px 0;
    }

    &__count {
      color: $light-grey-12;
    }

    &__select-wrap {
      display: inline-block;
      width: 266px;
    }

    &__source {
      &-wrap {
        display: flex;
        align-items: center;
        gap: 12px;
        margin-left: auto;
      }

      &-source {
        font: $fira-16;
      }

      &-field {
        width: 164px;
      }
    }

    ol {
      margin: 0 0 26px 0;
      counter-reset: list;
      li {
        counter-increment: list;
        font: $fira-16-20;
        letter-spacing: -0.02em;
        color: #000;

        &::before {
          content: counter(list) '.';
          padding-right: 8px;
        }

        &:not(:last-child) {
          margin-bottom: 12px;
        }
      }

      span {
        font: inherit;
        letter-spacing: inherit;
      }
    }

    &--select-title & {
      &__title-wrap {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
      }
    }

    @media (max-width: 830px) {
      &__source {
        &-wrap {
          margin-left: 0;
        }
      }
    }
  }
</style>

<style lang="scss">
  .exportPdf {
    .block-top-rating--select-title .block-top-rating__title-wrap {
      align-items: baseline;
    }

    .block-top-rating__select-wrap {
      width: auto;
      flex: 1 1 0%;

      .multiselect-wrapper {
        display: block;
      }

      .multiselect-single-label {
        position: relative;
      }

      .multiselect-single-label-text {
        white-space: normal;
      }

      .multiselect-caret {
        display: none;
      }
    }
  }
</style>
