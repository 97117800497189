import moment from 'moment';

export const EVENTS_LEGEND_ID = {
  planned: 1,
  needReport: 2,
  completed: 3,
  failed: 4,
  other: null,
};

export const EVENTS_LEGEND_COLORS = {
  [EVENTS_LEGEND_ID.planned]: '#25c05a',
  [EVENTS_LEGEND_ID.needReport]: '#ffd966',
  [EVENTS_LEGEND_ID.completed]: '#989898',
  [EVENTS_LEGEND_ID.failed]: '#FF6666',
  [EVENTS_LEGEND_ID.other]: '#000',
};

export const EVENTS_LEGEND_TEXT = {
  [EVENTS_LEGEND_ID.planned]: 'Мероприятие планируется',
  [EVENTS_LEGEND_ID.needReport]: 'По прошедшему мероприятию требуется отчет',
  [EVENTS_LEGEND_ID.completed]: 'Запланированное мероприятие состоялось/отчет предоставлен',
  [EVENTS_LEGEND_ID.failed]: 'Запланированное мероприятие не состоялось/отчет не предоставлен',
};

export const EVENTS_LEGEND_TITLES = {
  [EVENTS_LEGEND_ID.planned]: 'Запланированное',
  [EVENTS_LEGEND_ID.needReport]: 'Требуется отчет',
  [EVENTS_LEGEND_ID.completed]: 'Cостоявшееся',
  [EVENTS_LEGEND_ID.failed]: 'Несостоявшееся',
  [EVENTS_LEGEND_ID.other]: 'Прочее',
};

export const ForeignEconomicRelationTabs = {
  KEY_INDEX: { id: 1, text: 'Ключевые показатели' },
  TRADE_TURNOVER: { id: 2, text: 'Товарооборот' },
  COMMON_INFO: { id: 3, text: 'Общие сведения' },
  COUNTRY_PARTNERS: { id: 4, text: 'Основные страны-партнеры' },
  EVENTS: { id: 5, text: 'Мероприятия' },
  VES_ORGANIZATION: { id: 6, text: 'Орган, ответственный за ВЭС субъекта РФ' },
  AGREEMENTS: { id: 7, text: 'Соглашения' },
};

export const MAP_REGION_SCALE = [
  {
    color: '#D9D9D9',
    value: '0',
  },
  {
    color: '#BAD7F3',
    value: '1-20',
    from: 1,
    to: 20,
  },
  {
    color: '#7ABFFF',
    value: '21-40',
    from: 21,
    to: 40,
  },
  {
    color: '#2A97F9',
    value: '41-60',
    from: 41,
    to: 60,
  },
  {
    color: '#096BC7',
    value: '61-80',
    from: 61,
    to: 80,
  },
  {
    color: '#013F78',
    value: '81-100',
    from: 81,
    to: 100,
  },
];

export const OPTIONS_EVENTS_ID = 1;

export const SKELETON_GRAPHS = {
  graph: {
    datasets: [],
  },
  list: [],
};

export const AGREEMENTS_HISTORY_STATUS = Object.freeze({
  1: {
    id: '1',
    name: 'Реализуется',
    type: 'yellow',
  },
  2: {
    id: '2',
    name: 'Завершен',
    type: 'green',
  },
  3: {
    id: '3',
    name: 'Приостановлен',
    type: 'red',
  },
  4: {
    id: '4',
    name: 'В перспективе',
    type: 'darkBlue',
  },
});

export const PERIOD_CAP = 'Прошедший период';

export const DEFAULT_IMPLEMENTATION_FILTER = {
  regionRfId: null,
  startDate: moment().subtract(1, 'year').startOf('year').format(),
  endDate: moment().subtract(1, 'year').endOf('year').format(),
  partnerCountryId: null,
  federalDistrictId: null,
};

export const AgreementStatus = {
  PLAN_FOR_REALIZE: 'Запланировано к реализации',
  REALIZE: 'Реализуется',
  STOPPED: 'Приостановлен',
  DONE: 'Завершен',
};

export const EVENTS_PASSPORT_RELATION_LIMIT = 5;
