<template>
  <CardsWrapper>
    <IndicatorCard mod="inverted-card">
      <template v-slot:values>
        <div class="indicator-card__wrapper-indicators">
          <p class="indicator-card__value">{{ topRegionsAgreementsInfo?.regionCount ?? 0 }}</p>
          <div class="indicator-card__percent">
            <p :class="[getColorFromNumberDifference('regionCountLastYearDelta'), 'margin-5']">
              <i
                :class="[
                  'fas fa-solid arrow',
                  getColorFromNumberDifference('regionCountLastYearDelta'),
                  getColorFromNumberDifference('regionCountLastYearDelta', 'icon'),
                ]"
              ></i>
              {{ getTextRegionSubject('regionCountLastYearDelta') }}
            </p>
            <p :class="[getColorFromNumberDifference('partnerCountLastYearDelta'), 'margin-5']">
              <i
                :class="[
                  'fas fa-solid arrow',
                  getColorFromNumberDifference('partnerCountLastYearDelta'),
                  getColorFromNumberDifference('partnerCountLastYearDelta', 'icon'),
                ]"
              ></i>
              {{ getTextRegionParent('partnerCountLastYearDelta') }}
            </p>
          </div>
        </div>
        <p class="indicator-card__title">
          Субъектов РФ поддерживают ВЭС с {{ topRegionsAgreementsInfo?.partnerCount }} государствами
        </p>
      </template>
    </IndicatorCard>
    <IndicatorCard mod="inverted-card">
      <template v-slot:values>
        <div class="indicator-card__wrapper-indicators">
          <p class="indicator-card__value">{{ topRegionsAgreementsInfo?.eventCount ?? 0 }}</p>
          <div class="indicator-card__percent">
            <p :class="[getColorFromNumberDifference('eventCountLastYearDelta'), 'margin-5']">
              <i
                :class="[
                  'fas fa-solid arrow',
                  getColorFromNumberDifference('eventCountLastYearDelta'),
                  getColorFromNumberDifference('eventCountLastYearDelta', 'icon'),
                ]"
              ></i>
              {{ getValueFromNumberDifference('eventCountLastYearDelta') }}
            </p>
          </div>
        </div>
        <p class="indicator-card__title">Мероприятий проведено субъектами РФ в сфере ВЭС</p>
        <RouterLink class="indicator-card__link" to="/ves/events" title="Мероприятия ВЭС"></RouterLink>
      </template>
    </IndicatorCard>
    <IndicatorCard mod="inverted-card">
      <template v-slot:values>
        <div class="indicator-card__wrapper-indicators">
          <p class="indicator-card__value">{{ topRegionsAgreementsInfo?.agreementCount ?? 0 }}</p>
          <div class="indicator-card__percent">
            <p :class="[getColorFromNumberDifference('agreementCountLastYearDelta'), 'margin-5']">
              <i
                :class="[
                  'fas fa-solid arrow',
                  getColorFromNumberDifference('agreementCountLastYearDelta'),
                  getColorFromNumberDifference('agreementCountLastYearDelta', 'icon'),
                ]"
              ></i>
              {{ getValueFromNumberDifference('agreementCountLastYearDelta') }}
            </p>
          </div>
        </div>
        <p class="indicator-card__title">
          Соглашений заключено субъектами&nbsp;РФ на&nbsp;сумму {{ getPreparedAgreementSum }} млн рублей
        </p>
        <RouterLink class="indicator-card__link" to="/ves/implementation" title="Осуществления ВЭС"></RouterLink>
      </template>
    </IndicatorCard>
  </CardsWrapper>
</template>

<script>
  import IndicatorCard from '@/common/components/redesigned/IndicatorCard.vue';
  import wordDeclension from '@/common/mixins/wordDeclension';

  import CardsWrapper from '../CardsWrapper';

  export default {
    name: 'IndicatorsCards',
    mixins: [wordDeclension],
    components: {
      IndicatorCard,
      CardsWrapper,
    },
    props: {
      topRegionsAgreementsInfo: {
        type: Object,
        default: () => ({}),
      },
    },
    computed: {
      getPreparedAgreementSum() {
        if (!isNaN(this.topRegionsAgreementsInfo?.agreementSum)) {
          const formatter = new Intl.NumberFormat('ru-RU', { maximumFractionDigits: 1 });
          return formatter.format(this.topRegionsAgreementsInfo.agreementSum);
        }

        return '';
      },
    },
    methods: {
      getColorFromNumberDifference(val2, type) {
        const difference = +this.topRegionsAgreementsInfo[val2];
        if (type === 'icon') return difference >= 0 ? 'fa-arrow-up' : 'fa-arrow-down';
        return difference >= 0 ? 'text-green' : 'text-red';
      },

      getValueFromNumberDifference(val2) {
        const value = +this.topRegionsAgreementsInfo[val2];
        return value;
      },

      getTextRegionSubject(val2) {
        const value = +this.topRegionsAgreementsInfo[val2];
        const text = this.wordDeclension(value, ['субъект', 'субъекта', 'субъектов']);
        return `${value} ${text} РФ`;
      },

      getTextRegionParent(val2) {
        const value = +this.topRegionsAgreementsInfo[val2];
        const text = this.wordDeclension(value, ['страна-партнер', 'страны-партнера', 'стран-партнеров']);
        return `${value} ${text}`;
      },
    },
  };
</script>
